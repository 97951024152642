import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Post from "../components/Post"

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    postNode: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        title
        tags
        category
        date
        niceDate: date(formatString: "dddd, Do MMM YYYY")
      }
    }
  }
`;

export default ({ data }) => {
  const post = convertPost(data.postNode);
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'post' }} >
        <title>Peter Coulton</title>
      </Helmet>
      <Post post={post} />
    </Layout>
  );
};

const convertPost = (node) => ({
  path: node.fields.slug,
  title: node.frontmatter.title,
  tags: node.frontmatter.tags,
  date: node.frontmatter.date,
  niceDate: node.frontmatter.niceDate,
  body: node.body
});

